<template>
  <div class="container">
    <!--数据表格-->
    <div class="top" style="margin-bottom:10px">
      <el-button
        type="success"
        icon="el-icon-plus"
        size="large"
        @click="onAdd()"
        >新增</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column label="姓名" prop="name" align="center" />
      <el-table-column label="职位" prop="job" align="center" />

      <el-table-column label="操作" width="280" align="center">
        <template slot-scope="scope">
          <el-button type="success" size="small" @click="onUpdate(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" size="small" @click="onDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!-- 短信发送 -->
    <el-dialog
      :title="handleType === 'add' ? '新增领导' : '编辑领导'"
      width="400px"
      :visible.sync="smsDialog"
    >
      <div
        v-loading="smsLoading"
        class="dialog-body"
        element-loading-text="正在发送..."
      >
        <el-form :model="leaderForm" label-width="60px">
          <el-form-item label="姓名" required>
            <el-input
              v-model="leaderForm.name"
              type="text"
              placeholder="输入领导姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="职位" required>
            <el-input
              v-model="leaderForm.job"
              type="text"
              placeholder="输入领导职位"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="smsDialog = false">关 闭</el-button>
        <el-button v-if="handleType == 'add'" type="primary" @click="handleAdd"
          >提 交</el-button
        >
        <el-button
          v-if="handleType !== 'add'"
          type="primary"
          @click="handleUpdate"
          >提 交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getLeaderList,
  removeLeader,
  createLeader,
  updateLeader
} from '@/api/psychology';

export default {
  name: 'PsychologyList',
  data() {
    return {
      query: {
        policeNoOrTelephone: '',
        name: '',
        orderDate: '',
        orderType: '',
        status: 1
      },

      pageInfo: {
        startPage: 1,
        pageSize: 50,
        total: 0,
        policeNoOrTelephone: '',
        name: '',
        orderDate: '',
        orderType: '',
        status: 1,
        isToday: 1
      },
      orderTypeList: [],
      list: [],
      listLoading: true,
      dlgLoading: false,
      exportLoading: false,
      selectDlgShow: false,
      multipleSelection: [],
      smsLoading: false,
      smsDialog: false,
      checkAll: false,
      roleList: [],
      checkRoleList: [],
      isIndeterminate: true,
      handleType: '',
      leaderForm: {
        name: '',
        job: ''
      },
      smsForm: {
        ids: [],
        startTime: '',
        endTime: ''
      }
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onAdd() {
      this.handleType = 'add';
      this.smsDialog = true;
    },
    handleAdd() {
      createLeader(this.leaderForm).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.smsDialog = false;
          this.fetchData();
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
          this.smsDialog = false;
        }
      });
    },

    onUpdate(obj) {
      this.handleType = 'edit';
      this.leaderForm = obj;
      this.smsDialog = true;
    },

    handleUpdate() {
      updateLeader(this.leaderForm).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '更新成功!'
          });
          this.smsDialog = false;
          this.fetchData();
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
          this.smsDialog = false;
        }
      });
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //查询列表
    fetchData() {
      this.listLoading = true;

      getLeaderList(this.pageInfo).then(response => {
        this.list = response.data.data;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },

    //删除
    onDel(data) {
      this.$confirm('您确定要删除此条记录吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeLeader({ id: data.id })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
</style>
